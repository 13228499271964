// import { useState, useEffect, useRef } from "react";
// import { useNavigate } from "react-router-dom";
// import { useParams } from "react-router-dom";
// import { submitEntry } from './Model';

export default function Matchup({pair,pickTeam,thisGame,thisPick,isActive}){

    function handleKeyPress(e,tname){
        // console.log(e.keyCode);
        if (e.keyCode===13 || e.keyCode===32){
            pickTeam(thisGame,tname);
        }
    }

    const eitherEmpty = pair[0].name==='' || pair[1].name==='';

    return (
    <div className="matchup">
        {pair.map((team,t)=> {
            let thisclass = 'team-box';
            if (thisPick===team.name) {
                thisclass += ' active';
            }
            if (team.name==='') thisclass += ' empty';
            if (eitherEmpty) thisclass += ' disabled';
            return <div className={thisclass} onClick={e=>pickTeam(thisGame,team.name)} onKeyDown={e=>handleKeyPress(e,team.name)} key={thisGame+'-'+t} tabIndex={isActive?1:null} title={team.name}><span>{team.seed}</span> {team.name}</div>
        })}
    </div>
    );
}