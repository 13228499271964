import { useNavigate } from "react-router-dom";
import btb_white from '../assets/img/beatthebuffalo.png';
import btb_rev from '../assets/img/beatthebuffalo-rev.png';

export default function Header(){

    const navigate = useNavigate();

    function goHome(){
        navigate('/');
    }

    return (
        <header>
            <h1 onClick={goHome}>
                <img src={btb_white} alt="Beat the Buffalo" className='logo' />
                <img src={btb_rev} alt="Beat the Buffalo" className='logo_rev' />
            </h1>
            <a className="icon ig" href="https://www.instagram.com/bwwings/" target="_blank" rel="noreferrer">Instagram</a>
            <a className="icon tt" href="https://www.tiktok.com/@buffalowildwings?is_from_webapp=1&sender_device=pc" target="_blank" rel="noreferrer">TikTok</a>
            <a className="icon tw" href="https://x.com/BWWings?s=20" target="_blank" rel="noreferrer">X</a>
        </header>
    );
}