import { useState, useEffect, useRef, useLayoutEffect } from "react";
import { useParams, useNavigate, Navigate } from "react-router-dom";
import { useBeforeunload } from 'react-beforeunload';
import { updateEntry, getSessionID } from "./Model";
import Matchup from "./Matchup";

import bww_mm from '../assets/img/BWW_White_MM_OSBOMM_Lockup.png';


const regions = [ 'region1', 'region2', 'region3', 'region4' ];
const roundMap = {
    SIXTYFOUR : 0,
    THIRTYTWO: 1,
    SIXTEEN: 2,
    EIGHT: 3, 
    FOUR: 4,
    FINAL: 5,
    REGION1: 10,
    REGION2: 11,
    REGION3: 12, 
    REGION4: 13
};
const roundData = [
    {name:'First Round'},
    {name:'Second Round'},
    {name:'Sweet 16'},
    {name:'Elite Eight'},
    {name:'Final Four'},
    {name:'Championship Game'},
]
// const cityMap = {
//     "00" : "Louisville",
//     "01" : "New York",
//     "10" : "Kansas City",
//     "11" : "Las Vegas"
// };
const roundLimits = [
    32, 16, 8, 4, 2, 1, null, null, null, null, 15, 15, 15, 15, 3
]
const scrollTops = [
    0, 0, 76, 300, 600, 1275
]

export default function Bracket(){

    const [ viewMode, setViewMode ] = useState('sm');
    const [ round, setRound ] = useState(roundMap.SIXTYFOUR);
    // const [ game, setGame ] = useState(0);
    const [ picks, setPicks ] = useState({});
    const [ waiting, setWaiting ] = useState(false);
    const [ errors, setErrors ] = useState({});
    const bracketRef = useRef(null);
    const roundRef = useRef(null);

    const params = useParams();
    const navigate = useNavigate();
    // params.gender

    // load bracket data.
    const [ config, setConfig ] = useState(null);
    useEffect( ()=>{
        fetch('/config.json',{
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
        },
        })
        .then((res)=>res.json())
        .then((data)=>setConfig(data))
        .catch(_=>null);
    }, []);

    function toHalves(arr){
        const firstHalf = arr.slice(0,arr.length/2);
        const secondHalf = arr.slice(arr.length/2);
        return [firstHalf, secondHalf];
    }

    function handleKeyPress(e,r){
        if (e.keyCode===13 || e.keyCode===32){
            if (r===0){
                setRound(r);
            } else {
                toggleRegion(r);
            }
        }
    }
    function pickTeam(gamenum,pick){
        const roundsplit = gamenum.split('-');
        const region = roundsplit[1];
        const ground = region==='semi' || region==='final' ? roundsplit[1] : roundsplit[2];
        const gnum = region==='semi' ? roundsplit[2] : roundsplit[3];

        const groundToNum = {
            '64':0, '32':1, 'sixteen':2, 'regional':3, 'semi':4, 'final':5
        }
        const regionToNum = {
            'region1': 10, 'region2': 11, 'region3': 12, 'region4': 13, 'semi' : 14, 'final': 14
        }
        console.log(round, region, regionToNum[region]);

        // deal with clicking another round.
        if ( viewMode==='sm' && groundToNum[ground]!==round ) {
            if (groundToNum[ground]<round){ // allow going back.
                setRound( groundToNum[ground] );
            } else if (groundToNum[ground]>round) { 
                const progress = round<roundMap.FOUR
                    ? regions.reduce( (accumulator,region)=> accumulator+=getProgress(round,region), 0 )
                    : getProgress(round);
                if (progress===roundLimits[round]) { // allow going forward if current round is complete
                    setRound( groundToNum[ground] );
                    // if jumping ahead to final round, scroll it into position.
                    if ( groundToNum[ground]===5 ) {
                        const stop = scrollTops[5];
                        bracketRef.current.style.removeProperty('overflow-y');
                        bracketRef.current.scrollTo({top:stop,behavior:'smooth'});
                        setTimeout(()=>bracketRef.current.style.overflowY='hidden',500);
                    }
                } else {
                    // console.log('nope');
                    // otherwise do nothing.
                }
            }
            return;
        } else if ( viewMode==='md' && regionToNum[region]!==round ) {
            setRound( regionToNum[region] );
            const rname = region==='final' || region==='semi' ? 'final' : 'region-'+region;
            const regionAlignment = {
                'region1': 'start', 'region2': 'start', 'region3': 'end', 'region4': 'end', 'semi' : 'center', 'final': 'center'
            }
            setTimeout(()=>document.getElementById(rname).scrollIntoView({behavior:'smooth',block:'center',inline:regionAlignment[rname]}),500);
            return;
        };
        
        const existing = picks[gamenum];
        console.log('pick',gamenum,pick,existing);
        const npicks = { ...picks, [gamenum]:pick };

        // re-picking something that was already picked.
        if (existing && existing!==pick) {
            // clear out downstream picks.
            
            let hnum = gnum; // track r32 game
            let inum = gnum; // track r16 game

            // clear 1
            if (ground==='64'){
                hnum = Math.floor( Number(gnum)/2 );
                // console.log('kill 32/',hnum);
                delete npicks[`g-${region}-32-${hnum}`];
            }
            // clear 2
            if (ground==='64'||ground==='32'){
                inum = Math.floor( Number(hnum)/2 );
                // console.log('kill 16/',inum);
                delete npicks[`g-${region}-sixteen-${inum}`];
            }
            // clear 3
            if (ground==='64'||ground==='32'||ground==='sixteen'){
                delete npicks[`g-${region}-regional`];
            }
            // clear 4
            if (region==='region1' || region==='region2'){
                delete npicks['g-semi-0'];
            } else if (region==='region3' || region==='region4'){
                delete npicks['g-semi-1'];
            }
            // clear 5
            delete npicks['g-final'];
        }
        setPicks( npicks );

        
    }

    // take care of scrolling bracket view after making picks
    useEffect(()=>{ 
        const progress = round<roundMap.FOUR
            ? regions.reduce( (accumulator,region)=> accumulator+=getProgress(round,region), 0 )
            : getProgress(round);
        console.log('check progress', progress, roundLimits[round]);
        if (progress===roundLimits[round]) {
            console.log('go next round');
            setRound(round+1);
            
            if (viewMode==='sm'){
                const stop = scrollTops[round+1];
                console.log('go',round+1,stop);
                bracketRef.current.style.removeProperty('overflow-y');
                setTimeout(()=>bracketRef.current.scrollTo({top:stop,behavior:'smooth'}),10);
                if (round+1===5) setTimeout(()=>bracketRef.current.style.overflowY='hidden',500);
            } else if (viewMode==='md'){
                const numToRegion = {
                    10: 'region-region1', 11: 'region-region2', 12: 'region-region3', 13: 'region-region4', 14: 'final'
                }
                const nextRegion = numToRegion[round+1];
                const numToAlign = {
                    10: 'start', 11: 'start', 12: 'end', 13: 'end', 14: 'center'
                }
                setTimeout(()=>document.getElementById(nextRegion).scrollIntoView({block:'center',behavior:'smooth',inline:numToAlign[round+1]}),10);
            }
        } else {
            // scroll next into view    .
                const nm = document.querySelector('.matchup:not(:has(.active)):has(div[tabIndex]');
                if (nm) nm.scrollIntoView({behavior:'smooth',block:'nearest'});
        }
    },[picks]);

    // take care of scrolling round indicator after changing rounds
    useEffect(()=>{
        if (viewMode!='sm') return;
        if (!roundRef.current) return;
        const child = roundRef.current.children[round];
        child.scrollIntoView({behavior:'smooth'});
    },[round,roundRef]);

    // watch for screen size changes.
    useLayoutEffect(() => {
        function updateSize() {
          if (window.innerWidth >= 1920){
            setViewMode('lg');
          } else if (window.innerWidth >= 782){
            setViewMode('md');
          } else {
            setViewMode('sm');
          }
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    useEffect(()=>{
        // if viewmode changes, re-calculate round.
        // if (viewMode==='sm'){
        //     setRound(0);
        // } else if (viewMode==='md'){
        //     setRound(0);
        // } else {
        //     setRound(0);
        // }
        setRound(0);
    },[viewMode]);

    function gGN(a,b,c){ // get game num
        let round = '';
        let num = 0;
        if (c===undefined) {
            if (b===undefined){
                round = 'sixteen';
                num = a;
            } else {
                round = '32'
                num = (a*2) + b;
            }
        } else {
            round = '64'
            num = (a*4) + (b*2) + c;
        }
        return round+'-'+num;
    }

    function getProgress(round,region){
        const keys = Object.keys(picks);
        // const progress = {};

        if (round===roundMap.SIXTYFOUR){
            return keys.reduce( (accumulator,key)=>accumulator+=key.indexOf('g-'+region+'-64')>-1?1:0, 0 );
        } else if (round===roundMap.THIRTYTWO){
            return keys.reduce( (accumulator,key)=>accumulator+=key.indexOf('g-'+region+'-32')>-1?1:0, 0 );
        } else if (round===roundMap.SIXTEEN){
            return keys.reduce( (accumulator,key)=>accumulator+=key.indexOf('g-'+region+'-sixteen')>-1?1:0, 0 );
        } else if (round===roundMap.EIGHT){
            return keys.reduce( (accumulator,key)=>accumulator+=key.indexOf('g-'+region+'-regional')>-1?1:0, 0 );
        } else if (round===roundMap.FOUR){
            return keys.reduce( (accumulator,key)=>accumulator+=key.indexOf('g-semi')>-1?1:0, 0 );
        } else if (round===roundMap.TWO){
            return 1 + keys.indexOf('g-final');
        } else if (round===roundMap.REGION1 || round===roundMap.REGION2 || round===roundMap.REGION3 || round===roundMap.REGION4) {
            const numToRegion = {
                10: 'region1', 11: 'region2', 12: 'region3', 13: 'region4'
            }
            return keys.reduce( (accumulator,key)=>accumulator+=key.indexOf('g-'+numToRegion[round])>-1?1:0, 0 );
        }
        // return progress;
    }

    function toggleRound(e){
        const i = Number(e.target.getAttribute('data-index'));
        setRound(i);
        const stop = scrollTops[i];
        bracketRef.current.style.removeProperty('overflow-y');
        bracketRef.current.scrollTo({top:stop,behavior:'smooth'});
        if (i===5) setTimeout(()=>bracketRef.current.style.overflowY='hidden',500);
    }
    function toggleRegion(r){
        const regionToNum = {
            'region1': 10, 'region2': 11, 'region3': 12, 'region4': 13, 'semi' : 14, 'final': 14
        }
        setRound(regionToNum[r]);
        const regionAlignment = {
            'region1': 'start', 'region2': 'start', 'region3': 'end', 'region4': 'end', 'semi' : 'center', 'final': 'center'
        }
        const rname = r==='final' ? r : 'region-'+r;
        const delay = round>=10 ? 10 : 500; // wait longer if we are zooming in.
        setTimeout(()=>document.getElementById(rname).scrollIntoView({behavior:'smooth',block:'center',inline:regionAlignment[r]}),delay);
    }
    async function submitBracket(){
        if (waiting) return;
        if (!picks['g-final']) {
            console.log('no winner picked');
            return;     
        }
        setWaiting(true);
        const result = await updateEntry( picks );
        console.log(result);

        if (result.status===200){
            setWaiting(false);
            navigate('/thankyou/'+params.gender);
        } else {
            setWaiting(false);
            // // todo. set error based on code.
            setErrors({duplicate:true});
        }
        
    }

    function getBracketRound(){
        if (viewMode==='sm'){
            return `active-${round}`;
        } else if (viewMode==='md'){
            if (round>13){
                return 'punched active-final';
            } else if (round>=10) {
                return `punched active-${regions[round-10]}`;
            } else {
                return "";
            }
        } else {
            return '';
        }
    }
    function getActiveState(regionnum,roundnum){
        if (viewMode==='sm'){
            return roundnum===round;
        } else if (viewMode==='md'){
            if (regionnum===round-10){
                return true;
            } else {
                return false;
            }
        } else {
            return true;
        }
    }

    // prevent navigating away
    useBeforeunload((event) => event.preventDefault());

    return (
        <main className="bracket">
            {config ? 
                getSessionID()===null ?
                <Navigate to={`/form/${params.gender}`} replace={true} /> :
            <>
            <div className="heading">
                <h2>2024 NCAA { params.gender }'S BRACKET</h2>
                <p>Click on a matchup to build your March Madness bracket. Pick a better bracket than our buffalo and win big!</p>
                <button className={picks[`g-final`] ? 'active btn' : 'btn'} onClick={submitBracket}>Submit Bracket</button>
                {errors.duplicate && <p className="error">Whoops! Looks like you've already submitted a bracket!</p>}
                <div className="md-nav">
                    {round >= 10 && <a onClick={()=>setRound(0)} tabIndex={0} onKeyDown={e=>handleKeyPress(e,0)}>Full Bracket View</a>}
                    <div className="mini-bracket">
                        {regions.map(r=><div className="reg" key={`reg-${r}`} onClick={e=>toggleRegion(r)} tabIndex={0} onKeyDown={e=>handleKeyPress(e,r)}>{config[params.gender].regions[r]}</div>)}
                        <div className="center" onClick={e=>toggleRegion('final')} tabIndex={0} onKeyDown={e=>handleKeyPress(e,'final')}></div>
                    </div>
                    {/* <img src={bww_mm} alt="Beat the Buffalo" className='logo' /> */}
                </div>
            </div>

            <div className="round-indicator" ref={roundRef}>
                {roundData.map((r,i)=>
                    <div className={round===i?'active':''} onClick={toggleRound} data-index={i} key={`round-${i}`}>
                    {r.name.split(' ').map(e=><>{e}<br/></>)}
                    <span>{config[params.gender].dates[i]}</span>
                </div>
                )}
                {roundData.toReversed().map((r,i)=>
                    i>0&&<div className={'flipped'} onClick={toggleRound} data-index={5-i} key={`round-flip-${i}`}>
                    {r.name.split(' ').map(e=><>{e}<br/></>)}
                    <span>{config[params.gender].dates[5-i]}</span>
                </div>
                )}
            </div>
            <div className={`bracket-chart ${getBracketRound()}`} ref={bracketRef}>

                {/* <div className="region"> */}
                   {toHalves(config[params.gender].teams).map( ( half, h ) => 
                        <>
                        {toHalves(half).map( ( region, r ) => {
                        const rnum = parseInt(`${h}${r}`,2);
                        const rname = `region${rnum+1}`;
                        return <div className="region" key={`r-${h}${r}`} id={`region-${rname}`}>
                            <h3>{config[params.gender].regions[rname]}</h3>
                            <div className="r-64 sub-bracket"> {/* ROUND OF 64 */}
                            {toHalves(region).map( ( eights, e ) => 
                                toHalves(eights).map( (fours,f) =>
                                    toHalves(fours).map( ( twos, t ) => 
                                        <Matchup pair={twos} pickTeam={pickTeam} 
                                            thisGame={`g-${rname}-${gGN(e,f,t)}`} 
                                            thisPick={picks[`g-${rname}-${gGN(e,f,t)}`]} 
                                            key={`g-${rname}-${gGN(e,f,t)}`} isActive={getActiveState(rnum,roundMap.SIXTYFOUR)} />
                                    )
                                )
                            )}
                            </div>
                            <div className="r-32 sub-bracket"> {/* ROUND OF 32 */}
                            {toHalves(region).map( ( eights, e ) => 
                                toHalves(eights).map( (fours,f) => {
                                    const pair = toHalves(fours).map( (twos,t) => config[params.gender].teams.find( bt => bt.name===picks[`g-${rname}-${gGN(e,f,t)}`] ) || { seed:'',name:''} );
                                    return <Matchup pair={pair} pickTeam={pickTeam} 
                                    thisGame={`g-${rname}-${gGN(e,f)}`} 
                                    thisPick={picks[`g-${rname}-${gGN(e,f)}`]} 
                                    key={`g-${rname}-${gGN(e,f)}`} isActive={getActiveState(rnum,roundMap.THIRTYTWO)} />
                                })
                            )}
                            </div>
                            <div className="r-16 sub-bracket"> {/* SWEET 16 */}
                            {toHalves(region).map( ( eights, e ) => {
                                    const pair = toHalves(eights).map( (fours,f) => config[params.gender].teams.find( bt => bt.name===picks[`g-${rname}-${gGN(e,f)}`] ) || { seed:'',name:''} );
                                    return <Matchup pair={pair} pickTeam={pickTeam} 
                                    thisGame={`g-${rname}-${gGN(e)}`} 
                                    thisPick={picks[`g-${rname}-${gGN(e)}`]} 
                                    key={`g-${rname}-${gGN(e)}`} isActive={getActiveState(rnum,roundMap.SIXTEEN)} />
                            })}
                            <h4>{config[params.gender].regions[rnum]}</h4>
                            </div>
                            <div className="r-8 sub-bracket"> {/* ELITE 8 */}
                                <Matchup pair={toHalves(region).map( (eights,e) => config[params.gender].teams.find( bt => bt.name===picks[`g-${rname}-${gGN(e)}`] ) || { seed:'',name:''} )} pickTeam={pickTeam} 
                                        thisGame={`g-${rname}-regional`} 
                                        thisPick={picks[`g-${rname}-regional`]} 
                                        key={`g-${rname}-regional`} isActive={getActiveState(rnum,roundMap.EIGHT)} />
                            </div>

                        </div>}
                        )}
                        <div className="r-4 sub-bracket" id={`semi-${h}`}> {/* FINAL 4 */}
                            <Matchup pair={toHalves(half).map( (region,r) => config[params.gender].teams.find( bt => bt.name===picks[`g-region${parseInt(`${h}${r}`,2)+1}-regional`] ) || { seed:'',name:''} )} pickTeam={pickTeam} 
                                thisGame={`g-semi-${h}`} 
                                thisPick={picks[`g-semi-${h}`]} 
                                key={`g-semi-${h}`} isActive={getActiveState(4,roundMap.FOUR)}  />
                        </div>
                        </>
                   )}
                   <div className="r-2 sub-bracket" id={`final`}> {/* FINAL */}
                        <h3>Final Two</h3>
                        <Matchup pair={toHalves(config[params.gender].teams).map( (half,h) => config[params.gender].teams.find( bt => bt.name===picks[`g-semi-${h}`] ) || { seed:'',name:''} )} pickTeam={pickTeam} 
                            thisGame={`g-final`} 
                            thisPick={picks[`g-final`]} 
                            key={`g-final`} isActive={getActiveState(4,roundMap.FINAL)}  />
                        {picks[`g-final`] && <div className="winner" lang="en">
                            <h3>National Championship: your pick</h3>
                            <div className="winner-name" lang="en">{picks[`g-final`]}</div>
                            <button className="btn" onClick={submitBracket}>Submit Bracket</button>
                        </div>}
                    </div>
                {/* </div> */}

                <img src={bww_mm} alt="Beat the Buffalo" className='logo' />
            </div>

            <div className="progress-indicator">
                { round<roundMap.FOUR 
                 ? regions.map( (region,i)=>
                    <span key={region}>{getProgress(round,region)}/{roundLimits[round]/4}<br/>{ config[params.gender].regions[region] }</span>
                   ) 
                 : <>
                    <span>{getProgress(4)}/2<br/>SEMI FINALS</span>
                   </>
                }
            </div>
            </> : <>
                loading...
            </>}
        </main>
    );
}