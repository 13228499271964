import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useState } from 'react';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './components/Home';
import ComingSoon from './components/ComingSoon';
import Closed from './components/Closed';
import Form from './components/Form';
import Bracket from './components/Bracket';
import ThankYou from './components/ThankYou';
import Terms from './components/Terms';
import StreamTerms from './components/StreamTerms';
import './App.scss';
import { getContestState } from './util';
import { init } from './components/Model';

const BACKEND_URL = 'https://beat-the-buffalo-stage-9f7ef2f586e5.herokuapp.com'; // 'https://beatthebuffalo.com'; //'http://localhost:5000'; //process.env.REACT_APP_BACKEND_URL;

function App() {
  const apiPath = BACKEND_URL;
  const [ contestState, setContestState ] = useState(null); 

   // declare the data fetching function
   const fetchData = async () => {
    await init(apiPath,'');
    const s = getContestState();
    console.log('state',s);
    setContestState(s);
  }

  // call the function
  fetchData()
  // make sure to catch any error
  .catch(console.error);

  return (
    <div className="App">
      
      <Router>
        <Header />
        {contestState===-1 && <Routes>
          <Route path="/" Component={ComingSoon} />
          <Route path="/termsandconditions" Component={Terms} />
          <Route path="/livestreamtermsandconditions" Component={StreamTerms} />
        </Routes>}
        {contestState===0 && <Routes>
          <Route path="/" Component={Home} />
          <Route path="/form/:gender" Component={Form} />
          <Route path="/bracket/:gender" Component={Bracket} />
          <Route path="/thankyou/:gender" Component={ThankYou} />
          <Route path="/termsandconditions" Component={Terms} />
          <Route path="/closed" Component={Closed} /> 
          <Route path="/comingsoon" Component={ComingSoon} /> 
          <Route path="/livestreamtermsandconditions" Component={StreamTerms} />
        </Routes>}
        {contestState===1 && <Routes>
          <Route path="/" Component={Closed} />
          <Route path="/thankyou/:gender" Component={ThankYou} />
          <Route path="/termsandconditions" Component={Terms} />
          <Route path="/livestreamtermsandconditions" Component={StreamTerms} />
        </Routes>}
        <Footer />
      </Router>
      
    </div>
  );
}

export default App;
