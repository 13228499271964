// import { useState } from "react";
import { NavLink } from "react-router-dom";

export default function Home(){

    // const navigate = 

    return (
        <main className="home">
            <h2>Think your March Madness bracket can beat our buffalo's?</h2>
            <p>Beat the buffalo for a chance to win tickets to the next Final Four and free wings for a year.</p>
            <div>
                <NavLink to="/form/men" className="btn">Enter your<br/>Men's bracket</NavLink>
                <NavLink to="/form/women" className="btn">Enter your<br/>Women's bracket</NavLink>
            </div>
            <small>NO PURCHASE NECESSARY. A PURCHASE WILL NOT INCREASE YOUR CHANCES OF WINNING. Open only to legal residents of the 50 US/DC, 18 years of age and older. Void where prohibited by law. Sweepstakes starts at 12:00 a.m. ET on 3/18/24 and ends at 12:00 p.m. (noon) ET on 3/21/24. Subject to Official Rules, including how to enter, prize details, odds, all details and restrictions, <NavLink to="/termsandconditions">click here</NavLink>. Sponsor: Buffalo Wild Wings, Inc., 3 Glenlake Pkwy NE, Sandy Springs, GA 30328.</small>
        </main>
    );
}