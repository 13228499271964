import bww_mm from '../assets/img/BWW_White_MM_OSBOMM_Lockup.png';
import bww_mm_rev from '../assets/img/BWW_rev_MM_OSBOMM_Lockup.png';

export default function Footer(){

    // const navigate = 

    return (
        <footer>
            <img src={bww_mm} alt="Beat the Buffalo" className='logo' />
            <img src={bww_mm_rev} alt="Beat the Buffalo" className='logo_rev' />
        </footer>
    );
}