import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { submitEntry } from './Model';

export default function Form(){

    // const [ round, setRound ] = useState(roundMap.SIXTYFOUR);
    const [ values, setValues ] = useState({firstname:'',lastname:'',email:'',age:false, terms:false, resident:false});
    const [ errors, setErrors ] = useState({});
    // const [ picks, setPicks ] = useState({});
    // const bracketRef = useRef(null);
    const [ waiting, setWaiting ] = useState(false);
    const navigate = useNavigate();

    const params = useParams();
    // params.gender

    function updateValue(e){
        const id = e.target.getAttribute('id');
        const val = e.target.value;

        setValues({...values, [id]:val});
        // console.log(id,val);
    }
    function updateCheck(e){
        const id = e.target.getAttribute('id');
        const val = e.target.checked;
        // console.log(id,val);
        setValues({...values, [id]:val});
    }

    async function validate(e){
        e.preventDefault();
        e.stopPropagation();

        if (waiting) return;
        

        const fields = ["firstname","lastname","email","age","terms","resident"];
        const nerrors = {};

        if (values.firstname==='' || values.lastname==='' || values.email===''
            || !values.age || !values.terms || !values.resident ){
                fields.forEach(f=>{
                    if (values[f]==='' || values[f]===false){
                        console.log(f,'error');
                        nerrors[f] = true;
                    }
                })
            }
        if (!values.email.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            )){
            nerrors.email = true;
        }
        console.log(nerrors);
        setErrors(nerrors);

        if (Object.keys(nerrors).length===0){

            setWaiting(true);
            const result = await submitEntry( {...values,gender:params.gender});
            console.log(result);
            
            if (result.status===200){
                setWaiting(false);
                navigate('/bracket/'+params.gender);
            } else {
                setWaiting(false);
                // // todo. set error based on code.
                setErrors({duplicate:true});
            }
        }
    }

    return (
        <main className="form">
            <ol>
                <li>Enter your info to begin</li>
                <li>Make a bracket</li>
                <li>Don't be a loser</li>
            </ol>
            <form>
                <label htmlFor="firstname" className={errors.firstname&&'error'}>First Name</label>
                <input type="text" id="firstname" className={errors.firstname&&'error'} value={values.firstname} onChange={updateValue} />
                <label htmlFor="lastname" className={errors.lastname&&'error'}>Last Name</label>
                <input type="text" id="lastname" className={errors.lastname&&'error'} value={values.lastname} onChange={updateValue} />
                <label htmlFor="email" className={(errors.email || errors.duplicate)&&'error'}>Email</label>
                <input type="text" id="email" className={(errors.email || errors.duplicate)&&'error'} value={values.email} onChange={updateValue} />
                <div>
                    <input type="checkbox" id="age" className={errors.age&&'error'} checked={values.age} onChange={updateCheck} />
                    <label htmlFor="age" className={errors.age&&'error'}>I am over the age of 18.</label>
                </div>
                <div>
                    <input type="checkbox" id="terms" className={errors.terms&&'error'} checked={values.terms} onChange={updateCheck} />
                    <label htmlFor="terms" className={errors.terms&&'error'}>I agree to the <a href="/termsandconditions" target="_blank">terms and conditions</a>.</label>
                </div>
                <div>
                    <input type="checkbox" id="resident" className={errors.resident&&'error'} checked={values.resident} onChange={updateCheck} />
                    <label htmlFor="resident" className={errors.resident&&'error'}>I am a US resident.</label>
                </div>
                <input type="submit" value="Continue to bracket" className="btn" onClick={validate} />
                { Object.keys(errors).length>0 && 
                <div className="error_messages">
                    { (Object.keys(errors).length>1 || (!errors.email && !errors.duplicate && Object.keys(errors).length>0)) &&
                    <li>Please fill in all fields.</li>}
                    {errors.email && <li>Please enter a valid email address.</li>}
                    {errors.duplicate && <li>This email has already been used.</li>}
                </div>}
            </form>
        </main>
    )
}