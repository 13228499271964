import axios from 'axios';

let api = '';
// let apiPath = '';
let apiPostfix = '';

// let localsettings = '';

let session;
let _time = null; // new Date(2022,9,7,20,1);

export async function init(_apiPath,_apiPostfix){
    // console.log('init');
    api = _apiPath; // axios.create({ baseURL: _apiPath });
    // apiPath = _apiPath;
    apiPostfix = _apiPostfix;

    await getTimeAsync();
    return true;
}

export async function getTimeAsync(){
    // console.log('getTime',_time);
    if (!_time) {
        const { data:result } = await axios.get(api+'/time'+apiPostfix);
        // console.log(result);
        _time = new Date(result.init).getTime();
    }
    return _time;
}
export function getTime(){
    if (_time) return _time;
}

export function getSessionID(){
    return session ? session.id : null;
}


export async function updateEntry(bracket){
    const { data:result } = await axios.post(api+'/update'+apiPostfix,{
        id:session.id,
        gender:session.gender,
        bracket:bracket
    });
    return result;
}
export async function submitEntry(submission){
    const { data:result } = await axios.post(api+'/submit'+apiPostfix, submission);
    session = { id: result.id, gender: submission.gender };
    console.log(result);
    return result;
}
export function resetSession(){
    session = null;
}

// todo. error handling.