// import { useState, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

export default function ThankYou(){

    const params = useParams();

    return (
        <main className="thankyou">
            <h2>thanks! you’ve successfully entered your <span>{params.gender}'s</span> bracket.<br/>Good luck!</h2>
            <div>
                <NavLink to="/" className="btn">Back<br/>to Home</NavLink>
                <a href="https://www.instagram.com/bwwings/" className="btn" target="_blank" rel="noreferrer">Follow our<br/>Buffalo on Social</a>
            </div>
        </main>
    );
}